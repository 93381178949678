.show-print {
    display: none !important;
}

.lbd-proposal-options button {
    background: white;
    color: black;
    border: 1px solid black;
    padding: .25rem;
    border-radius: .25rem;
    margin: 0 .25rem .25rem 0;
}

.lbd-proposal-options button.active {
    background: black;
    color: white;
}

.lbd-page .table tr {
    border-left: 0;
    border-right: 0;
}

.lbd-page .table td {
    padding: 5px 5px 5px 0;
}

.lbd-page .table tr:first-child {
    border-top: 0;
}

.lbd-page .table tr:last-child {
    border-bottom: 0;
}

.lbd-page a {
    color: black;
    text-decoration: none;
}

.lbd-cover {
    page-break-after: auto !important;
    height: 100vh;
}

.lbd-cover > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lbd-cover hr {
    border-top: 1px solid black;
    width: 100%;
}

.lbd-cover h1 {
    font-weight: lighter;
}

.lbd-page input[type="text"] {
    width: 200px;
    margin-bottom: 1rem;
}

.lbd-page textarea {
    height: 100px;
    width: 60%;
}

.brand-color > div {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: .25rem 1rem;
}

header.brand-color > div {
    border-bottom: 5px solid black;
}

header.brand-color .contact-info {
    align-self: center;
}

header.brand-color img {
    width: 200px;
}

.unit {
    align-self: center;
    font-size: 20px;
    font-weight: bold;
    font-family: sans-serif;
}

.proposal-static-map .static-map {
    margin-right: 1rem;
    width: 100%;
    height: auto;
}

.proposal-unit-image {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.proposal-details-container {
    margin-top: 1rem;
    display: flex;
}

.proposal-details-container > div {
    flex-basis: 50%;
}

.proposal-details {
    padding-left: 1rem;
}

.proposal-details h3 {
    margin: 0 0 1rem;
}

@page {
    margin: 5mm;
}

@media print {

    body {
        background: white !important;
    }
    html.wp-toolbar {
        padding: 0 !important;
    }
    #wpadminbar,
    #adminmenumain,
    #screen-meta,
    #wpfooter,
    h1 {
        display: none !important;
    }
    #wpcontent, #wpbody-content {
        margin: 0 !important;
        padding: 0 !important;
        float: none !important;
    }

    #lbd-map {
        display: none;
    }

    .show-print {
        display: initial !important;
    }

    .hidden-print {
        display: none !important;
    }

    html,body {
        height: 100%;
    }

    .lbd-page {
        page-break-after: always;
        height: 99%;
        display: block;
        position: relative;
        margin: 0;
    }

    .lbd-page.lbd-cover {
        height: 100vh !important;
    }

    .lbd-page:last-child {
        page-break-after: auto;
    }

    .lbd-proposal-options {
        display: none !important;
    }
}
